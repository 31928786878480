<template>
  <div>
    <section>
      <h3 class="page-header py-3">Select available features</h3>
      <LoaderIcon v-if="getFeatureSetupLoading" />
      <b-form v-else id="feature-setup-form" name="feature-setup-form" autocomplete="off" @submit.prevent="onSubmitFeatureSetup" novalidate>
        <b-form-row v-for="(feature, index) in featureSetupList" :key="index">
          <b-col cols="12" sm="10" md="8" lg="6">
            <b-form-group>
              <b-form-checkbox v-model="feature.isEnabled" :id="`feature-setup-checkbox-${index}`" :name="`feature-setup-checkbox-${index}`">
                <h4 class="mt-1 page-sub-header">
                  {{ feature.toggleFeatureName }}
                </h4>
              </b-form-checkbox>
              <p class="mb-0 ml-4 font-14" v-if="feature.toggleFeatureDesc">{{ feature.toggleFeatureDesc }}</p>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="my-3">
          <b-col>
            <b-button type="submit" id="save-feature-setup-btn" v-activeBlur variant="primary" :disabled="updateFeatureSetupLoading"
              >Save <b-spinner v-if="updateFeatureSetupLoading" label="Spinning" small class="ml-2"></b-spinner
            ></b-button>
            <b-button type="button" id="cancel-feature-setup-btn" v-activeBlur class="ml-3" @click="resetFeatureSetupForm" variant="outline-secondary"
              >Cancel</b-button
            >
          </b-col>
        </b-form-row>
      </b-form>
    </section>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash'
import { useAxios } from '@/composables/useAxios'
import { ServiceUrls } from '@/utilities/serviceUrls'
import LoaderIcon from '@/assets/svg/loader.svg'
export default {
  name: 'FeatureSetup',
  setup() {
    const { isLoading: getFeatureSetupLoading, response: getFeatureSetupResponse, error: getFeatureSetupError, callApi: getFeatureSetupApi } = useAxios()

    const {
      isLoading: updateFeatureSetupLoading,
      response: updateFeatureSetupResponse,
      error: updateFeatureSetupError,
      callApi: updateFeatureSetupApi,
    } = useAxios()

    return {
      getFeatureSetupLoading,
      getFeatureSetupResponse,
      getFeatureSetupError,
      getFeatureSetupApi,
      updateFeatureSetupLoading,
      updateFeatureSetupResponse,
      updateFeatureSetupError,
      updateFeatureSetupApi,
    }
  },
  data() {
    return {
      formSubmitted: false,
      featureSetupList: [],
    }
  },
  components: {
    LoaderIcon,
  },
  computed: {
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  mounted() {
    this.getFeatureSetupList()
  },
  methods: {
    showToaster(message, key, type) {
      this.$store.commit('common/setCustomToastData', {
        message: message,
        key: key,
        type: type,
      })
    },
    async getFeatureSetupList() {
      const url = `${ServiceUrls.getFeatureSetupList}/${this.$route.params.tenantID}`
      await this.getFeatureSetupApi({ method: 'get', url })
      if (this.getFeatureSetupResponse) {
        this.featureSetupList = cloneDeep(this.getFeatureSetupResponse)
      }
      if (this.getFeatureSetupError) {
        this.showToaster(false, 'ERROR_GET_FEATURE_SETUP', 'danger')
      }
    },
    resetFeatureSetupForm() {
      this.featureSetupList = cloneDeep(this.getFeatureSetupResponse)
    },
    async onSubmitFeatureSetup() {
      this.formSubmitted = true
      await this.updateFeatureSetupApi({ method: 'post', url: ServiceUrls.updateFeatureSetupList, data: this.featureSetupList })
      if (this.updateFeatureSetupResponse) {
        this.$store.dispatch('common/getAdminTenants')
        this.formSubmitted = false
        this.showToaster(false, 'UPDATE_FEATURE_SETUP', 'success')
      }
      if (this.updateFeatureSetupError) {
        if (this.updateFeatureSetupError?.errorMessage) {
          this.showToaster(this.updateFeatureSetupError?.errorMessage, false, 'danger')
        } else {
          this.showToaster(false, 'FAILED', 'danger')
        }
      }
    },
  },
  watch: {
    selectedTenant(newValue, oldValue) {
      this.getFeatureSetupList()
    },
  },
}
</script>
<style lang="scss" scoped></style>
